@import "../../../styles/breakpoints";

.projects {
  .projects-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 300px;

    @media all and (max-width: $mobile) {
      flex-direction: column;
      margin-bottom: 40px;

      h3 {
        margin-bottom: 20px;
      }
    }

    ul {
      display: flex;

      li {
        text-transform: uppercase;
        padding: 4px 10px;
        margin: 0 10px;
        font-weight: 700;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        @media all and (max-width: $mobile) {
          padding: 3px 5px;
          margin: 0 5px;
          font-size: 0.8rem;
        }

        &:hover {
          border: 1px solid var(--secondary300);
        }

        &.active {
          border: 1px solid var(--secondary600);
        }
      }
    }
  }

  .items-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: $mobile) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .item {
    position: relative;
    text-transform: uppercase;
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 160px;
    cursor: pointer;

    @media all and (max-width: $mobile) {
      flex-basis: 100%;
      margin-bottom: 60px;
    }

    figure {
      background: var(--secondary900);
      width: 75%;
      position: relative;
      overflow: hidden;
      margin: 0;

      h5 {
        position: absolute;
        left: 50%;
        top: 50%;
        text-transform: uppercase;
        color: var(--primary);
        transform: translate(-50%, -50%);
        transition: opacity 0.4s ease-out;
        opacity: 0;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        opacity: 0.95;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1);
        transform-origin: 50% 50%;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        content: "";
        -webkit-transition: -webkit-transform 0.6s;
        transition: transform 0.6s;
        -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
      }

      &:hover {
        img {
          opacity: 0.6;
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }

        h5 {
          opacity: 1;
        }

        &::before {
          -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
            translate3d(0, 100%, 0);
          transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
            translate3d(0, 100%, 0);
        }
      }
    }

    figure:hover &:before {
      content: "";
      position: absolute;
      left: 15px;
      top: -69px;
      height: 36px;
      width: 1px;
      background: var(--secondary300);
    }

    h5 {
      margin-bottom: 32px;
    }

    h2 {
      position: absolute;
      top: -2px;
      left: 0;
      opacity: 0.05;
    }

    &:nth-child(2n + 1) {
      margin-top: -160px;

      @media all and (max-width: $mobile) {
        margin-top: 0;
      }
    }

    &:nth-child(2n) {
      align-items: flex-end;

      figure {
        margin: 0 0 0 auto;
      }

      &:before {
        left: auto;
        right: 15px;
      }

      h2 {
        left: auto;
        right: 0;
      }
    }
  }
}
