:root {
  --primary: #ffffff;

  --secondary900: #353d3d;
  --secondary800: #282c34;
  --secondary700: #717171;
  --secondary600: #adadad;
  --secondary500: #beb7b7;
  --secondary400: #bdbdbd;
  --secondary300: #dbdbdb;
  --secondary200: #ededed;
  --secondary150: #e0e0e0;
  --secondary100: #eaeaea;
  --secondary50: #fbfcfc;

  --accent400: #00c2ff;
  --accent500: #1abcfe;
}
