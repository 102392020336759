@import "../../../styles/breakpoints";

.intro-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 75px auto;

  @media all and (max-width: $mobile) {
    flex-direction: column;
  }

  img {
    flex-shrink: 0;
    margin-left: 90px;

    @media all and (max-width: $mobile) {
      margin-left: 0;
    }
  }

  .expertise {
    display: flex;
    flex-direction: column;
    h4 {
      margin-bottom: 40px;
    }

    .skillset {
      li {
        margin-bottom: 35px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 20px;
          flex-shrink: 0;
        }
      }
    }
  }
}
