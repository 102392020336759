body {
  background: url("../assets/jpg/background.jpg") repeat;
}

a {
  text-decoration: none;
  color: var(--secondary900);
}

a:visited {
  color: var(--secondary900);
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

::-moz-selection {
  background: var(--secondary900);
  color: var(--primary);
}

::selection {
  background: var(--secondary900);
  color: var(--primary);
}

.content {
  padding: 0 40px;
  width: calc(100% - 80px);
  margin: 0 auto;
  color: var(--secondary900);

  @media all and (max-width: $mobile) {
    padding: 0 15px;
    width: calc(100% - 30px);
  }

  @media all and (min-width: 1440px) {
    width: 1200px;
    flex-basis: 1200px;
  }

  @media all and (min-width: 1600px) {
    width: 1400px;
    flex-basis: 1400px;
  }

  @media all and (min-width: 1920px) {
    width: 1600px;
    flex-basis: 1600px;
  }

  @media all and (min-width: 2450px) {
    width: 1800px;
    flex-basis: 1800px;
  }
}
