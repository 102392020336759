@import "../../styles/breakpoints";

.wrapper {
  padding: 55px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  @media all and (max-width: $mobile) {
    flex-direction: column;
    padding: 25px 0;
  }

  .title {
    display: flex;
    align-items: center;

    svg .animated {
      transform: translateX(0);
      transition: all 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    }

    &:hover svg {
      .animated {
        transform: translateX(-10px);
      }
    }

    h6 {
      margin-left: 20px;
    }

    @media all and (max-width: $mobile) {
      margin-bottom: 25px;
    }
  }
}
