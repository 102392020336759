@import "../../styles/breakpoints";

footer {
  margin-top: 70px;
}

.links {
  display: flex;

  @media all and (max-width: $mobile) {
    flex-direction: column;
  }

  > div {
    width: 50%;
    padding: 64px 0;

    @media all and (max-width: $mobile) {
      width: 100%;
      padding: 32px 0;
    }
  }

  .social {
    border-top: 1px solid var(--secondary200);

    @media all and (max-width: $mobile) {
      display: flex;
      justify-content: center;
    }

    a {
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

      &:hover {
        color: var(--secondary700);
      }

      &:not(:last-of-type) {
        margin-right: 34px;
      }
    }
  }

  .nav {
    text-transform: uppercase;
    border-bottom: 1px solid var(--secondary200);
    display: flex;
    justify-content: flex-end;
  }
}

.copyright {
  display: flex;
  align-items: center;
  padding: 50px 0;

  p {
    margin-left: 20px;
    color: var(--secondary600);
    font-size: 14px;
    line-height: 1.3;
  }
}
