html {
  font-size: 100%; /* 16px */
}

/* typescale: Augmented Fourth 1.414 */

body {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  line-height: 1.75;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 5.653rem;
  letter-spacing: -4px;
}

h2 {
  font-size: 3.998rem;
  letter-spacing: -2px;
}

h3 {
  font-size: 2.827rem;
  letter-spacing: -2px;
}

h4 {
  font-size: 1.999rem;
  letter-spacing: -2px;
}

h5 {
  font-size: 1.414rem;
}

h6 {
  font-size: 1rem;
}

small,
.text_small {
  font-size: 0.707rem;
}

.text_medium {
  font-size: 0.85rem;
}

.text_body {
  font-size: 1.25rem;
  line-height: 1.5;
}

@media all and (max-width: $mobile) {
  h1 {
    margin-top: 0;
    font-size: 3rem;
    letter-spacing: -2px;
  }

  h2 {
    font-size: 2rem;
    letter-spacing: -2px;
  }

  h3 {
    font-size: 1.999rem;
    letter-spacing: -2px;
  }

  h4 {
    font-size: 1.414rem;
  }

  h5 {
    font-size: 1rem;
  }

  .text_body {
    font-size: 1rem;
    line-height: 1.4;
  }
}
