@import "../../../styles/breakpoints";

.testimonials {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;

  .note {
    display: flex;
    flex-direction: column;
    margin-bottom: 156px;
    position: relative;
    text-align: center;

    @media all and (max-width: $mobile) {
      margin-bottom: 70px;
    }

    h5 {
      font-weight: 400;

      a {
        text-decoration: none;
        color: var(--accent400);
      }
    }

    h5:first-of-type {
      position: relative;
      margin-bottom: 120px;

      &:after {
        content: "";
        height: 56px;
        width: 1px;
        background: var(--secondary300);
        position: absolute;
        left: 50%;
        top: 60px;
      }
    }

    h2 {
      position: absolute;
      opacity: 0.05;
      top: -50px;
      text-transform: uppercase;
      width: 100%;
      pointer-events: none;

      @media all and (max-width: $mobile) {
        top: -25px;
      }

      &:last-of-type {
        top: auto;
        bottom: -55px;

        @media all and (max-width: $mobile) {
          bottom: -25px;
        }
      }
    }
  }
}
