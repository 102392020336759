@import "../../../styles/breakpoints";

.experience-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 75px;
  position: relative;

  @media all and (max-width: $mobile) {
    flex-direction: column;
  }

  &:before {
    content: "";
    height: 1px;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--secondary200);
  }

  .education,
  .experience {
    display: flex;
    flex-direction: column;
    padding-right: 70px;
    flex: 1 1 50%;

    svg {
      flex-shrink: 0;
    }

    h4 {
      position: relative;
      padding-bottom: 45px;

      &:after {
        content: "";
        height: 28px;
        width: 1px;
        position: absolute;
        bottom: 10px;
        left: 14px;
        background: var(--secondary200);
      }
    }
  }

  .experience {
    padding-right: 0;

    ul {
      display: flex;
      margin: 20px 0 0;
      text-transform: uppercase;
      font-weight: 700;

      @media all and (max-width: $mobile) {
        flex-direction: row;
      }

      li {
        position: relative;
        margin-right: 24px;

        &:after {
          content: "";
          height: 2px;
          width: 40%;
          position: absolute;
          bottom: -4px;
          left: 0;
          background: var(--accent400);
          transition: width 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:hover:after {
          width: 100%;
        }
      }
    }
  }

  .education {
    @media all and (max-width: $mobile) {
      flex-basis: 100%;
      width: 100%;
      padding-right: 0;
      align-items: center;

      h4 {
        width: 100%;
      }
    }
    .universities {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        position: relative;
        margin-bottom: 20px;

        svg {
          margin-right: 17px;
        }

        i {
          font-style: normal;
          color: var(--secondary400);
        }

        h5 i {
          margin-left: 12px;
          font-weight: 400;
        }

        @media all and (max-width: $mobile) {
          h5 {
            display: flex;
            flex-direction: column;

            i {
              margin-left: 0;
              margin-bottom: 5px;
            }
          }
        }

        &:first-of-type {
          &:after {
            content: "";
            height: 20px;
            width: 1px;
            position: absolute;
            bottom: -7px;
            left: 14px;
            background: var(--secondary200);
          }
        }
      }
    }
  }
}
